
import ContentCard from 'portal/components/common/content-card/ContentCard.vue';
import ContentCardSwiper from 'portal/components/common/content-card/ContentCardSwiper.vue';
import ContentBlockWrapperWithHeader from 'portal/components/common/content-block/ContentBlockWrapperWithHeader.vue';

import { chunkArray } from 'portal/assets/js/helpers/helpers';

export default {
    name: 'ThreeToThreeBlock',
    components: {
        ContentBlockWrapperWithHeader,
        ContentCardSwiper,
        ContentCard,
    },

    props: {
        bigCard: {
            type: Object,
            required: true,
            default: () => ({}),
        },

        smallCards: {
            type: Array,
            required: true,
            default: () => [],
        },

        slug: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            default: null,
        },

        buttonText: {
            type: String,
            default: null,
        },

        buttonLink: {
            type: String,
            default: null,
        },

        firstScreenBlock: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        chunkedCards() {
            return chunkArray(this.smallCards, 3);
        },

        hasBigCard() {
            return Object.keys(this.bigCard).length > 0;
        },
    },
};
