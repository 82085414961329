// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content_aE193{display:grid;gap:2rem;grid-template-columns:repeat(2,1fr)}@media(max-width:1279.98px){.content_aE193{display:none}}.firstContainer_nP\\+w9{margin-right:2rem}.card_OXlxt{max-width:49.8rem}.swiper_WKuQ1{display:none}@media(max-width:1279.98px){.swiper_WKuQ1{display:flex;flex-direction:column;max-width:90vw}}.button_dWnKV{align-items:center;background-color:#17181c;border-radius:1.2rem;display:flex;justify-content:center;margin-top:2.4rem}.text_Ww\\+et{margin-right:.8rem}.icon_rZeN5,.text_Ww\\+et{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"content": "content_aE193",
	"firstContainer": "firstContainer_nP+w9",
	"card": "card_OXlxt",
	"swiper": "swiper_WKuQ1",
	"button": "button_dWnKV",
	"text": "text_Ww+et",
	"icon": "icon_rZeN5"
};
module.exports = ___CSS_LOADER_EXPORT___;
